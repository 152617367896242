import {
  ProcessorCategoryNameEnum,
  ProcessorNameEnum,
} from "../../../shared/enums/ProcessorsEnum";
import { IProcessor, IProcessors } from "./useProcessors.interface";
import { useEffect, useMemo, useState } from "react";
import { ITableRowProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableSimple/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { selectNodeInfo } from "../../../store/selectors/selectors";
import { assign, defaultTo, get, isEmpty, isNil, set } from "lodash";
import { PathEnum } from "../../../shared/enums/pathEnum";
import { useHistory, useLocation } from "react-router";
import { QueryParamsEnum } from "../../../shared/enums/queryParamsEnum";
import { IMerchantInfo } from "../../DeferredList/state/useDeferredList.interface";
import { LOCAL_STORAGE_ITEMS } from "../../../shared/constants/local_storage_items";
import axios from "../../../shared/axios-util";
import { API_ROUTES } from "../../../shared/constants/api_routes";
import { GetMerchantProcessorsResponse } from "../../../../types/get_merchant_processors_response";
import { AxiosResponse } from "axios";
import {
  setMerchantDefaultProcessors,
  setMerchantProcessors,
} from "../../../store/actions/actions";
import { ITableCellProps } from "@kushki/connect-ui/dist/Components/Organism/Table/TableInfo/interfaces";
import {
  BASE_CELL,
  TAG_CELL,
  TEXT_ACTION_CELL,
  TEXT_CELL,
  TEXT_TOOLTIP_CELL,
} from "../../../shared/infrastructure/constants/DeferredListConstants";
import { ALL_TABLE_COLUMNS } from "../../../shared/infrastructure/constants/ProcessorsConstants";
import {
  IconCircleFill,
  IconCopy,
  IRowInteractiveProps,
} from "@kushki/connect-ui";
import { PAYMENT_METHOD } from "../../../shared/enums/PaymentMethodsEnum";
import moment from "moment";
import { GetMerchantDefaultProcessorsResponse } from "../../../../types/get_merchant_default_processors_response";
import { isCentralizedNode } from "../../../shared/utils/isCentralizedNode";
import { ConfigurationIdEnum } from "../../../shared/enums";
import { EntityNameEnum } from "../../../shared/enums/entityName";
import { ProcessorStatus } from "../../../shared/infrastructure/status-enum";
import { PayoutPaymentTypeEnum } from "../../../shared/enums/PayoutPaymentType";

const setLocalStorageItem = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const getLocalStorageItem = (key: string): string => {
  return defaultTo(localStorage.getItem(key), "{}");
};

export const useProcessors = (): IProcessors => {
  const [rows, setRows] = useState<ITableRowProps[]>([]);
  const [entityName, setEntityName] = useState<string>("");
  const [isCentralized, setIsCentralized] = useState<boolean | null>(null);
  const history = useHistory();
  const nodeInfo = useSelector(selectNodeInfo);
  const [country, setCountry] = useState<string>("");

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const publicMerchantId: string | null = searchParams.get(
    QueryParamsEnum.PUBLIC_MERCHANT_ID
  );
  const merchantInfo: IMerchantInfo | null = JSON.parse(
    getLocalStorageItem(LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION)
  );
  const dispatch = useDispatch();

  const getMerchantIdRequest = (
    isCentralizedNode = isCentralized
  ): string | null => {
    const isBranchCentralized =
      entityName === EntityNameEnum.BRANCH && isCentralizedNode;
    const isCustomer = entityName === EntityNameEnum.CUSTOMER;

    if (isCustomer) return publicMerchantId;
    else if (isBranchCentralized && nodeInfo) {
      const configCN007 = nodeInfo!.configs?.find(
        (config) => config.configuration === ConfigurationIdEnum.CN007
      );

      return get(configCN007, "value", publicMerchantId);
    }

    return publicMerchantId;
  };

  const getMerchantProcessors = async (): Promise<
    GetMerchantProcessorsResponse[]
  > => {
    try {
      const processorResponse: AxiosResponse<GetMerchantProcessorsResponse[]> =
        await axios.get<GetMerchantProcessorsResponse[]>(
          `${API_ROUTES.MERCHANT_PROCESSORS}/${publicMerchantId}/processors`
        );

      return processorResponse.data;
    } finally {
    }
  };

  const getMerchantDefaultProcessors = async (
    isCentralizedNode = isCentralized
  ): Promise<GetMerchantDefaultProcessorsResponse> => {
    try {
      const value = getMerchantIdRequest(isCentralizedNode);
      const processorResponse: AxiosResponse<GetMerchantDefaultProcessorsResponse> =
        await axios.get<GetMerchantDefaultProcessorsResponse>(
          `${API_ROUTES.MERCHANT_DEFAULT_PROCESSORS}/${value}`
        );

      return processorResponse.data;
    } finally {
    }
  };

  const getMerchantPayoutsProcessors = async (
    isCentralizedNode = isCentralized
  ): Promise<GetMerchantDefaultProcessorsResponse> => {
    try {
      const merchantId: string = defaultTo(
        getMerchantIdRequest(isCentralizedNode),
        ""
      );
      const processorResponse: AxiosResponse<GetMerchantDefaultProcessorsResponse> =
        await axios.get<GetMerchantDefaultProcessorsResponse>(
          `${API_ROUTES.MERCHANT_PAYOUTS_PROCESSORS}${merchantId}`
        );

      return processorResponse.data;
    } catch (e) {
      return { defaultProcessor: {} };
    }
  };

  const getDefaultPayoutsProcessor = (
    processors: GetMerchantProcessorsResponse[],
    defaultPayoutsProcessors: GetMerchantDefaultProcessorsResponse,
    payoutPaymentType: PayoutPaymentTypeEnum,
    defaultProcessors?: GetMerchantDefaultProcessorsResponse
  ): string => {
    const defaultPayoutProcessor: GetMerchantProcessorsResponse | undefined =
      processors.find(
        (processor: GetMerchantProcessorsResponse) =>
          processor.publicProcessorId ==
          get(defaultPayoutsProcessors.defaultProcessor, payoutPaymentType, "")
      );

    if (payoutPaymentType === PayoutPaymentTypeEnum.PAYOUT_CARD)
      return get(defaultPayoutProcessor, "alias", "");

    return isEmpty(defaultProcessors!.defaultProcessor.payoutsTransfer)
      ? get(defaultPayoutProcessor, "alias", "")
      : get(defaultProcessors, "defaultProcessor.payoutsTransfer", "");
  };

  const getCells = (
    column: IProcessor,
    defaultProcessors: GetMerchantDefaultProcessorsResponse
  ): ITableCellProps[] => {
    const dateToShow = defaultTo(column.updatedAt, column.created);
    const alias = defaultTo(column.alias, "");
    const processorStatus: string = isEmpty(column.status)
      ? ProcessorStatus.ENABLED
      : column.status!;
    const isDefaultProcessor = Object.values(
      defaultProcessors.defaultProcessor
    ).includes(alias);

    return [
      BASE_CELL(
        isDefaultProcessor ? (
          <IconCircleFill fontSize={"small"} color={"info"} />
        ) : (
          <></>
        )
      ),
      TEXT_CELL(
        moment(dateToShow).format("DD-MM-YYYY"),
        moment(dateToShow).format("HH:mm:ss")
      ),
      TEXT_CELL(alias),
      TEXT_ACTION_CELL(defaultTo(column.publicProcessorId, ""), <IconCopy />),
      TEXT_CELL(defaultTo(PAYMENT_METHOD[column.paymentMethod], "")),
      column.failOverProcessor
        ? TEXT_TOOLTIP_CELL(
            defaultTo(column.processorType, ""),
            defaultTo(column.categoryModel, ""),
            `El Failover asignado es ${column.failOverProcessor}`
          )
        : TEXT_CELL(
            defaultTo(ProcessorNameEnum[column.processorType], ""),
            defaultTo(ProcessorCategoryNameEnum[column.categoryModel], "")
          ),
      TAG_CELL(processorStatus),
    ];
  };

  const getFirstFiveElements = (
    elements: GetMerchantProcessorsResponse[]
  ): GetMerchantProcessorsResponse[] => {
    const response = elements.slice().sort((a, b) => {
      const dateA = defaultTo(a.updatedAt, a.created);
      const dateB = defaultTo(b.updatedAt, b.created);

      return dateB - dateA;
    });

    return response.slice(0, 5);
  };

  const findProcessors = (isCentralizedNode = isCentralized) => {
    Promise.all([
      getMerchantProcessors(),
      getMerchantDefaultProcessors(isCentralizedNode),
      getMerchantPayoutsProcessors(isCentralizedNode),
    ]).then(([processors, defaultProcessors, defaultPayoutsProcessors]) => {
      set(
        defaultProcessors,
        "defaultProcessor.payoutsTransfer",
        getDefaultPayoutsProcessor(
          processors,
          defaultPayoutsProcessors,
          PayoutPaymentTypeEnum.PAYOUT_TRANSFER,
          defaultProcessors
        )
      );
      set(
        defaultProcessors,
        "defaultProcessor.payoutsCard",
        getDefaultPayoutsProcessor(
          processors,
          defaultPayoutsProcessors,
          PayoutPaymentTypeEnum.PAYOUT_CARD
        )
      );
      dispatch(setMerchantDefaultProcessors(defaultProcessors));
      dispatch(setMerchantProcessors(processors));

      let items: GetMerchantProcessorsResponse[];

      if (processors.length > 5) items = getFirstFiveElements(processors);
      else items = processors;

      const valuesToRow: ITableRowProps[] = items.map(
        (item: GetMerchantProcessorsResponse, index: number) => {
          const cells = getCells(item, defaultProcessors);

          return {
            cells,
            id: "rules" + index,
            rowProps: {
              color: "default",
            } as IRowInteractiveProps,
          };
        }
      );

      setRows(valuesToRow);
    });
  };

  useEffect(() => {
    if (nodeInfo) {
      const centralized = isCentralizedNode(nodeInfo);

      setIsCentralized(centralized);
      const merchantBasicInfo: IMerchantInfo = {
        ...merchantInfo,
        country: get(nodeInfo, "generalInfo.country", ""),
        entityName: get(nodeInfo, "entityName", ""),
        isCentralized: centralized,
        name: get(nodeInfo, "generalInfo.name", ""),
      };

      setLocalStorageItem(
        LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION,
        JSON.stringify(merchantBasicInfo)
      );
      setEntityName(nodeInfo.entityName);
      setCountry(get(nodeInfo, "generalInfo.country", ""));
    }
  }, [nodeInfo]);

  useEffect(() => {
    if (!isNil(isCentralized) && !isEmpty(nodeInfo)) findProcessors();
  }, [isCentralized]);

  const addDataToStorage = () => {
    const merchantBasicInfo: object = JSON.parse(
      getLocalStorageItem(LOCAL_STORAGE_ITEMS.MERCHANT_BASIC_INFORMATION)
    );

    assign(merchantBasicInfo, {
      country: get(nodeInfo, "generalInfo.country", ""),
      entityName,
      isCentralized,
      name: get(nodeInfo, "generalInfo.name", ""),
      origin: "merchantResume",
      publicMerchantId: getMerchantIdRequest(),
    });

    setLocalStorageItem(
      LOCAL_STORAGE_ITEMS.MERCHANT_BASIC_INFORMATION,
      JSON.stringify(merchantBasicInfo)
    );
  };

  const handleEdit = (): void => {
    addDataToStorage();
    history.push(`${PathEnum.PROCESSOR}${publicMerchantId}`);

    const item = defaultTo(
      localStorage.getItem(LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION),
      "{}"
    );

    localStorage.setItem(
      LOCAL_STORAGE_ITEMS.BASIC_MERCHANT_INFORMATION,
      JSON.stringify({
        ...JSON.parse(item),
        origin: "createMerchantV3",
      })
    );
  };

  const handleAddProcessor = () => {
    addDataToStorage();
  };

  const isShowAddButton = useMemo((): boolean => {
    const isEmptyTable = rows.length === 0;
    const isBranchNotCentralized =
      entityName === EntityNameEnum.BRANCH && !isCentralized;
    const isCustomer = entityName === EntityNameEnum.CUSTOMER;

    return isEmptyTable && (isCustomer || isBranchNotCentralized);
  }, [rows, entityName, isCentralized]);

  return {
    country,
    entityName,
    handleAddProcessor,
    handleEdit,
    isCentralized,
    isShowAddButton,
    publicMerchantId: defaultTo(getMerchantIdRequest(), ""),
    table: {
      columns: ALL_TABLE_COLUMNS,
      rows,
    },
  };
};
