export enum PaymentMethodsEnum {
  payoutsTransfer = "payouts-transfer",
  transfer = "transfer",
  payoutsCard = "payouts-card",
  payoutsCash = "payouts-cash",
  cash = "cash",
  transferSubscriptions = "transfer-subscriptions",
  card = "card",
  internationalTransfer = "internationalTransfer",
}

export const PAYMENT_METHOD: Record<PaymentMethodsEnum, string> = {
  [PaymentMethodsEnum.payoutsTransfer]: "Transferencia / Pay Out",
  [PaymentMethodsEnum.transfer]: "Transferencia / Pay In",
  [PaymentMethodsEnum.payoutsCash]: "Efectivo / Pay Out",
  [PaymentMethodsEnum.cash]: "Efectivo / Pay In",
  [PaymentMethodsEnum.transferSubscriptions]: "Débito recurrente / Pay In",
  [PaymentMethodsEnum.card]: "Tarjeta / Pay In",
  [PaymentMethodsEnum.payoutsCard]: "Tarjeta / Pay Out",
  [PaymentMethodsEnum.internationalTransfer]:
    "Transferencia Internacional/ Pay Out",
};

export enum PaymentTypesEnum {
  PAY_IN = "Pay-In",
  PAY_OUT = "Pay-Out",
  INTERNATIONAL_TRANSFER = "Dispersión internacional",
}
