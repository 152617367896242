export const CreateProcessorRoutes = {
  CREATE_CARD_PROCESSOR: (merchantId: string) =>
    `/processor-card/${merchantId}`,
  CREATE_CASH_PROCESSOR: (merchantId: string) =>
    `/processor-cash/${merchantId}`,
  CREATE_INTERNATIONAL_TRANSFER_PROCESSOR: (merchantId: string) =>
    `/processor-international-transfer/${merchantId}`,
  CREATE_PAYOUTS_CARD_PROCESSOR: (merchantId: string) =>
    `/processor-payouts-card/${merchantId}`,
  CREATE_PAYOUTS_CASH_PROCESSOR: (merchantId: string) =>
    `/processor-payouts-cash/${merchantId}`,
  CREATE_PAYOUTS_TRANSFER_PROCESSOR: (merchantId: string) =>
    `/processor-payouts-transfer/${merchantId}`,
  CREATE_TRANSFER_PROCESSOR: (merchantId: string) =>
    `/processor-transfer/${merchantId}`,
  CREATE_TRANSFER_SUBS_PROCESSOR: (merchantId: string) =>
    `/processor-transfer-subscriptions/${merchantId}`,
};
